body {
  margin: 0;
  font-size: 16px;
  overflow: hidden;
}

main {
  width: 100vw;
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
  }

  &:hover {
    .box {
      filter: grayscale(1);
    }
  }
}

.box {
  width: 100%;
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.3s ease-in-out;
  background: {
    color: #909090;
    size: cover;
    repeat: no-repeat;
    position: center;
  };

  &:hover {
    filter: grayscale(0) !important;

    svg {
      transform: scale(1.05);
    }
  }

  @media (min-width: 768px) {
    width: 50vw;
    height: 100vh;
  }

  &--salon {
    background-image: url('./img/gabinet@mob.jpg');

    @media (min-width: 768px) {
      background-image: url('./img/gabinet.jpg');
    }
  }

  &--shop {
    background-image: url('./img/sklep@mob.jpg');

    @media (min-width: 768px) {
      background-image: url('./img/sklep.jpg');
    }
  }

  svg {
    height: 144px;
    width: 144px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.16);
    transition: transform 0.3s ease-in-out;

    @media (min-width: 768px) {
      height: 220px;
      width: 220px;
    }
  }
}

.hide {
  display: none;
}